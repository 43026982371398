import '@fortawesome/fontawesome-free/css/all.css'
import {closeAllDropdownLists, closeAllModals, closeModal, showModal, toggleCollapse} from './helpers'

(function () {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark')
    } else {
        document.documentElement.classList.remove('dark')
    }

    const menu = document.getElementById('aareii-menu')
    const menuCloseButton = document.getElementById('aareii-menu-close-button')
    if (menuCloseButton) {
        menuCloseButton.addEventListener('click', (evt) => {
            evt.stopPropagation()
            menu.classList.add('hidden')
            menu.classList.remove('flex')
        })
    }
    const menuOpenButton = document.getElementById('aareii-menu-open-button')
    if (menuOpenButton) {
        menuOpenButton.addEventListener('click', (evt) => {
            evt.stopPropagation()
            menu.classList.add('flex')
            menu.classList.remove('hidden')
        })
    }

    const modals = Array.from(document.getElementsByClassName('modal'))
    modals.forEach((button) => {
        button.addEventListener('click', (evt) => {
            evt.preventDefault()
            evt.stopPropagation()
            closeAllModals()
        })
    })
    const modalDialogs = Array.from(document.getElementsByClassName('modal-dialog'))
    modalDialogs.forEach((button) => {
        button.addEventListener('click', (evt) => {
            evt.stopPropagation()
        })
    })
    const modalButtons = Array.from(document.getElementsByClassName('modal-button'))
    modalButtons.forEach((button) => {
        button.addEventListener('click', (evt) => {
            evt.preventDefault()
            evt.stopPropagation()
            showModal(button.dataset.slModalId)
        })
    })
    const modalCloseButtons = Array.from(document.getElementsByClassName('modal-close-button'))
    modalCloseButtons.forEach((button) => {
        button.addEventListener('click', (evt) => {
            evt.stopPropagation()
            closeModal(button.dataset.slModalId)
        })
    })
    document.addEventListener('click', function (evt) {
        if (!evt.target.classList.contains('dropdown-list')) {
            evt.stopPropagation()
            closeAllDropdownLists()
        }
    })
    const collapseButtons = Array.from(document.getElementsByClassName('collapse-button'))
    collapseButtons.forEach((button) => {
        button.addEventListener('click', (evt) => {
            evt.stopPropagation()
            toggleCollapse(button.dataset.slCollapseId)
            const chevron = button.children.item(button.children.length - 1)
            if (chevron.classList.contains('fa-chevron-down')) {
                chevron.classList.remove('fa-chevron-down')
                chevron.classList.add('fa-chevron-up')
            } else {
                chevron.classList.remove('fa-chevron-up')
                chevron.classList.add('fa-chevron-down')
            }
        })
    })
})()

/**
 * Convertir un número en un texto según el formato especificado
 * @param {int} c cantidad de decimales
 * @param {string} d separador decimal
 * @param {string} t separador milésimo
 * @return {string} el número escrito según el formato especificado
 */
Number.prototype.formatNumber = function (c, d, t) {
    c = isNaN(c = Math.abs(c)) ? 2 : c
    d = d === undefined ? "." : d
    t = t === undefined ? "," : t
    let n = this,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = i.length > 3 ? i.length % 3 : 0
    return s + (j ? i.substring(0, j) + t : "") + i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "")
}

Window.prototype.redirect = function (evt, path) {
    evt.preventDefault()
    evt.stopPropagation()
    window.location.href = path
}

Window.prototype.redirectBlank = function (evt, path) {
    evt.preventDefault()
    evt.stopPropagation()
    window.open(path, '_blank')
}

Window.prototype.closeAlerts = function () {
    document.getElementById('msg').innerHTML = ''
}

Window.prototype.showSuccess = function (text) {
    document.getElementById('msg').innerHTML =
        `<div class="flex flex-col gap-1 px-3 py-2 rounded-lg bg-green-200 dark:bg-green-800 border border-green-300 dark:border-green-900 text-green-800 dark:text-green-100 shadow-lg shadow-slate-400 dark:shadow-slate-700">
		<div class="flex items-center gap-2">
			<i class="fa-solid fa-check-circle"></i>
			<p class="flex-grow font-semibold">Success</p>
			<div class="h-6 w-6 flex items-center justify-center cursor-pointer hover:bg-green-300 dark:hover:bg-green-700 rounded" onclick="window.closeAlerts()">
				<i class="fa-solid fa-times"></i>
			</div>
		</div>
		<p>${text}</p>
	</div>`
}

Window.prototype.showInfo = function (text) {
    document.getElementById('msg').innerHTML =
        `<div class="flex flex-col gap-1 px-3 py-2 rounded-lg bg-blue-200 dark:bg-blue-800 border border-blue-300 dark:border-blue-900 text-blue-800 dark:text-blue-100 shadow-lg shadow-slate-400 dark:shadow-slate-700">
		<div class="flex items-center gap-2">
			<i class="fa-solid fa-info-circle"></i>
			<p class="flex-grow font-semibold">Info</p>
			<div class="h-6 w-6 flex items-center justify-center cursor-pointer hover:bg-blue-300 dark:hover:bg-blue-700 rounded" onclick="window.closeAlerts()">
				<i class="fa-solid fa-times"></i>
			</div>
		</div>
		<p>${text}</p>
	</div>`
}

Window.prototype.showError = function (text) {
    document.getElementById('msg').innerHTML =
        `<div class="flex flex-col gap-1 px-3 py-2 rounded-lg bg-red-200 dark:bg-red-800 border border-red-300 dark:border-red-900 text-red-800 dark:text-red-100 shadow-lg shadow-slate-400 dark:shadow-slate-700">
		<div class="flex items-center gap-2">
			<i class="fa-solid fa-times-circle"></i>
			<p class="flex-grow font-semibold">Error</p>
			<div class="h-6 w-6 flex items-center justify-center cursor-pointer hover:bg-red-300 dark:hover:bg-red-700 rounded" onclick="window.closeAlerts()">
				<i class="fa-solid fa-times"></i>
			</div>
		</div>
		<p>${text}</p>
	</div>`
}

Window.prototype.showWarning = function (text) {
    document.getElementById('msg').innerHTML =
        `<div class="flex flex-col gap-1 px-3 py-2 rounded-lg bg-yellow-200 dark:bg-yellow-800 border border-yellow-300 dark:border-yellow-900 text-yellow-800 dark:text-red-100 shadow-lg shadow-slate-400 dark:shadow-slate-700">
		<div class="flex items-center gap-2">
			<i class="fa-solid fa-exclamation-triangle"></i>
			<p class="flex-grow font-semibold">Warning</p>
			<div class="h-6 w-6 flex items-center justify-center cursor-pointer hover:bg-yellow-300 dark:hover:bg-yellow-700 rounded" onclick="window.closeAlerts()">
				<i class="fa-solid fa-times cursor-pointer"></i>
			</div>
		</div>
		<p>${text}</p>
	</div>`
}

Window.prototype.showValidationErrors = function (error) {
    window.closeAlerts()
    if (error.response) {
        if(error.response.status === 419){
            window.showWarning('Sesión inválida: intentá usar el modo incógnito o ingresar desde otro navegador/dispositivo.')
        } else {
            window.showError(`${error.response.status}: ${error.response.data.message}`)
        }
    } else {
        window.showError(error)
    }
}